
export const serverMessage = {
  serverErrorHeader: "Oops !!!",
  message:"Message: ",
  //serverAPIerror:"Server Error: Failed to reach server.",
  serverAPIerror:"Server Error: Please check the data before updating.",//new
  serverErrorMessage: "Server Error: Data cannot be loaded.",
  dataSaveMessage: "Data saved !",
  dataNotSaveMessage:"Data not saved !",
  warning:"Warning !!!",
  serverNotSaveMessage:"Server Error: Data can not be saved.",
  excelExportMessage:"Excel exported",
  excelImportMessage:"Excel imported",
  excelnotImportMessage:"Uploaded File is not Excel type",
  ValuesNotMatchedMessage:"Check Excel for proper values",
  HeadereTitleNotMatched: "Can not Change Headers/add new data row in: ActualUnitId, ProductId, ParentId, Year, ProductTitle, MonthHeaders and IsReadOnly fields ",
  IsReadOnlyError:"Can Not modify/add data In: IsReadOnly column",
  ActualUnitIdError:"Can Not Modify Values In: ActualUnitId column",
  ParentIdError:"Can Not Modify Values In: ParentId column",
  ProductIdError:"Can Not Modify Values In: ProductId column",
  YearError:"Can Not Edit Values In: Year",
  ActualUnitIdError1:"Can not change: ActualUnitId",
  YearError1:"Can not Modify year in: Year column",
  ProductTitleError:"Can Not Modify Product Name In: Product column",
  MonthHeadersError:"Can Not Modify Month Headers/add new data row",
  YearsNotMatched:"Please Change The Year Through Dropdown. Imported Excel File Does not match with this year",
  NewRowAddError:"Can not Change Headers/add new data row in: ProductId, ParentId, ProductTitle, IsReadOnly and Year fields",
  AlphabetsError:"Only Numerics Allowed in: MonthColumns",
  RangeLengthError:"Entered digits lenth is > 10. Please enter data in the mentioned length only",
  WorksheetError:"Please upload Plan Unit's excel only",
  NoDataFound:"No Data Found!",
  validateerrormessage:"only Numerics Allowed",
  WorksheetError2:"Please upload Actual Unit's Excel only",
  Blanksheeterror:"Cant use blank excel file!",
  ActualUntIDProductError:"Can Not Modify ActualUnit Id or ProductName",
  SelectCategory:"Please Select Category, Year and Month before importing",
  dateError1: "Data cannot be changed. Your measure reports actuals values in closed periods. To ensure accuracy in books and records historic values cannot be changed, hence your change is not allowed.",
  currencyError: "Entered currency is not available. Please select a currency from the list.",
  // selectCurrency: "Please select currency in the list",
  // FXRate Import Error Messages
  ExcelMonthError:"Please Upload correct year & month excel",
  VAMPopUpMessage:"OPW Lever VAM was derived.\n Therefore OPW Filter \"VAM action P&L effect\" was selected and requires the selection of a OPW Sub Filter. Please select accordingly.",
  fpoValidationMessage:"Please fill all the mandatory fields in OPW - Classification page before checking Approved by FPO",


  ideaSearch:{
    dataDeleted:"Data deleted !",
    dataNotDeleted:"Data not deleted !",
    dataNotDeletedServer:"Server Error: Data can not be deleted.",
    dataCopied: "Data copied !"
  }
};