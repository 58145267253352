import { Component, OnInit, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from 'src/app/alert-dialog/alert-dialog.component';
import { UserInfo } from 'src/app/models/user-info';
import { PeopleDropDownComponent } from 'src/app/people-drop-down/people-drop-down.component';
import { messages } from 'src/app/popUpMessages/messages';
import { serverMessage } from 'src/app/popUpMessages/serverMessage';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { UserInfoService } from 'src/app/services/user-info.service';
import { DeleteiconComponent } from '../../deleteIconNormalGrid/deleteicon.component';
// Checkbox Component;
import { CheckboxComponent } from '../checkbox/checkbox.component';

import { UserReplacementService } from 'src/app/services/user-replacement.service';
import { DialogComponent } from 'src/app/dialog/dialog.component';
import { PeoplePickerService } from 'src/app/services/people-picker.service';


@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class UserManagementComponent implements OnInit {

  public rowData: any;
  public defaultColDef: any;
  public columnDefs: any;
  public gridApi: any;
  public gridColumnApi: any;
  public domLayout: any;
  public rowClassRules: any;
  public rowHeight: any;
  public headerHeight: any;

  public alertErrorBox = [];
  public modifiedUserRows = [];
  public errorMessage = []
  public roleMasterData = [];

  //for role dropdown data that are binded
  public roleDropDownData = [];

  //to set the default role as normal user when new row is added
  public defaultRole: any;

  public submitResponse: any;
  public loggedInUser: UserInfo;
  public finalError = [];
  public data: any;
  public frameworkComponents: any;
  public submitted = false;
  public showGrid = false;
  public postData: any;
  public searchedData: any;
  hideExportImport: boolean = true;

  // changes;
  public adminSubscription: any;
  public unsvdAdminChanges: boolean = false;
  @Output() unSavedChanges = new EventEmitter<any>();

  // user active;
  public allActiveAndInActiveUser: any = [];
  public allInActiveUser: any = [];
  public activateUserMessage: string; 

  // end;

  // changes for business area and line dropdown
  public roleBusinessArea = [];
  public roleAllBusinessLine = [];
  public roleBusinessLine = [];
  public disableBusinessAreaDropdown: boolean = false;
  public disableBusinessLineDropdown: boolean = false;

  selectBusinessArea = "";
  selectBusinessLine = "";


  constructor(
    private userSvc: UserInfoService,
    private userRemovalSvc: UserReplacementService,
    private sharedService: SharedServiceService,
    public dialog: MatDialog,
    private toast: ToasterService,
    private peoplePickerService: PeoplePickerService,

  ) {
    this.frameworkComponents = { checkboxRenderer: CheckboxComponent, userDropdownEditor: PeopleDropDownComponent };
  }

  ngOnInit(): void {
    this.sharedService.loggedInUser.subscribe(
      (user: any) => {
        this.loggedInUser = user;
        
        this.roleBusinessArea = user.BusinessArea;
        this.roleAllBusinessLine = user.BusinessLine;
        this.roleBusinessArea = this.roleBusinessArea.filter((v,i,a)=>a.findIndex(v2=>(v2.BusinessAreaId===v.BusinessAreaId))===i);

        if (this.roleBusinessArea.length == 1) {
          this.selectBusinessArea = this.roleBusinessArea[0].BusinessAreaId;
          this.roleBusinessLine = this.roleAllBusinessLine;
          this.disableBusinessAreaDropdown = true;
        }
        if (this.roleAllBusinessLine.length == 1) {
          this.selectBusinessLine = this.roleAllBusinessLine[0].BusinessLineId;
          this.disableBusinessLineDropdown = true;
          this.hideExportImport = false;
        }
        else{this.hideExportImport = true;}
      }
    )

    // subscribe to manage data eventemitter;
    this.adminSubscription = this.sharedService.userManagement.subscribe(() => {
      this.submit();
    })

    this.sharedService.userManagementDeletedRows.subscribe(
      res => {
        if (res.UserId != 0) {
          // changes;
          let valToEmit = {
            unsavedChanges: true,
            saveButtonClick: false
          }
          this.unsvdAdminChanges = false;
          this.unSavedChanges.emit(valToEmit);

          // end;
          let isPushed = false;
          res.IsActive = false
          if (this.modifiedUserRows.length == 0) {
            this.modifiedUserRows.push(res)
            isPushed = true
          }
          else {
            this.modifiedUserRows.forEach(row => {
              if (row.UserId == res.UserId) {
                isPushed = true
              }
            });
          }
          if (!isPushed) {
            this.modifiedUserRows.push(res)
          }
        }
      }
    )
    this.getRoleMasterData();
    this.sharedService.getIdeaIdAndtitle();
  }

  updateBusinessLine() {
    this.hideExportImport = true;
    this.selectBusinessLine = "";
    this.roleBusinessLine = this.roleAllBusinessLine.filter(
      bl => bl.BusinessAreaId == Number(this.selectBusinessArea)
    );
    this.getUserList();
    this.getAllActiveAndInActiveUserList()
  }

  updateUserList() {
    if(this.selectBusinessLine == "")
   {
    this.hideExportImport = true;
   }
   else
   {
    this.hideExportImport = false;
   }
    this.getUserList();
    this.getAllActiveAndInActiveUserList()
  }
  exportGridData() {
    this.showGrid = false;
    this.gridApi.exportDataAsExcel({
      columnKeys: ['GID','Email','FirstName','LastName','FullName','OrgCode','BusinessAreaName','BusinessLineName','RoleTitle','OnLegalHold'],
      protection: true,
      sheetName: "User Management",
      fileName: "User Management",
    });
    this.showGrid = true;
    this.toast.notify(serverMessage.excelExportMessage, "success");
  }

  // Fetch role master data to get the role title and role id to bind the Role field
  getRoleMasterData() {
    this.userSvc.getRoleMasterData().subscribe((res: any) => {

      let errorMsgeFromBack = res.Data.Message;
      if (res.StatusCode == 200) {
        if (res.Data) {
          this.roleMasterData = res.Data;
          // For binding role dropdown
          for (let role of this.roleMasterData) {
            let obj = {};
            obj["name"] = role.RoleTitle;
            obj["id"] = role.RoleId;
            this.roleDropDownData.push(obj);

            // To set the role to normal user when a new row is added
            if (role.RoleId == 3) {
              this.defaultRole = { name: role.RoleTitle, id: role.RoleId }
            }
          }
          this.getUserList();
          this.getAllActiveAndInActiveUserList();
        }
      }
      else {
        //Show errorCode and errorMessage in the UI;
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer);
      }
    },
      (error) => {
        //Show errorCode and errorMessage in the UI
        this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
      }
    )
  }

  // active user;
  //Fetch All Active and InActive user list to be displayed
  getAllActiveAndInActiveUserList() {
    this.userRemovalSvc.getUserList(Number(this.selectBusinessArea), Number(this.selectBusinessLine)).subscribe((res: any) => {
      if (res == null) {
        return
      }
      else {
        this.allActiveAndInActiveUser = res;
        for (let data of this.allActiveAndInActiveUser) {
          for (let role of this.roleDropDownData) {
            if (data.RoleId == role.id) {
              data.Role = role
            }
          }
        }
        this.allInActiveUser = [];
        for (let i of this.allActiveAndInActiveUser) {
          if (!i.IsActive && i.FirstName != "Deleted" && i.LastName != "User") {
            this.allInActiveUser.push(i)
          }
        }
      }
    })
  }

  //Fetch user list to be displayed
  getUserList() {
    this.showGrid=false;
    this.userSvc.getUserList(Number(this.selectBusinessArea), Number(this.selectBusinessLine)).subscribe((res: any) => {
      if (res == null) {
        return
      }
      else {
        this.rowData = res;
        for (let data of this.rowData) {
          for (let role of this.roleDropDownData) {
            if (data.RoleId == role.id) {
              data.Role = role
            }
          }
          data["userSoftDelete"] = true;
        }
       
        this.loadGrid()
      }
    })
  }

  //loads the grid structure after user list is fetched
  loadGrid() {
    let regex = new RegExp(/^\\s*$/);
    this.columnDefs = [
      {
        headerName: "GID",
        field: "GID",
        tooltipField: "GID",
        headerTooltip: "GID",
        cellStyle: (params) => params.data.GID == "" || params.data.GID == null
          || regex.test(params.data.GID) ? { borderLeft: "5px solid #a94442" } : { borderLeft: "" },
        valueGetter: convUpperCase
      },
      {
        headerName: "Email",
        field: "Email",
        headerTooltip: "Email",
        tooltipField: "Email",
        cellEditor: "userDropdownEditor",
        cellStyle: (params) => params.data.Email == "" || params.data.Email == null ? { borderLeft: "5px solid #a94442" } : { borderLeft: "" }
      },
      {
        headerName: "First Name",
        field: "FirstName",
        headerTooltip: "First Name",
        editable: false,
        tooltipField: "FirstName",
        cellStyle: (params) => params.data.FirstName == "" || params.data.FirstName == null ? { borderLeft: "5px solid #a94442" } : { borderLeft: "" }
      },
      {
        headerName: "Last Name",
        field: "LastName",
        headerTooltip: "Last Name",
        editable: false,
        tooltipField: "LastName",
        cellStyle: (params) => params.data.LastName == "" || params.data.LastName == null ? { borderLeft: "5px solid #a94442" } : { borderLeft: "" }
      },
      {
        headerName: "Full Name",
        field: "FullName",
        headerTooltip: "Full Name",
        editable: false,
        tooltipField: "FullName",
        cellStyle: (params) => params.data.FullName == "" || params.data.FullName == null ? { borderLeft: "5px solid #a94442" } : { borderLeft: "" }
      },
      {
        headerName: "Org Code",
        headerTooltip: "Org Code",
        field: "OrgCode",
        editable: false,
        tooltipField: "OrgCode",
        cellStyle: (params) => params.data.OrgCode == "" || params.data.OrgCode == null ? { borderLeft: "5px solid #a94442" } : { borderLeft: "" }
      },
      {
        headerName: "Business Area",
        headerTooltip: "Business Area",
        field: "BusinessAreaName",
        editable: false,
        tooltipField: "BusinessArea",
        cellStyle: (params) => params.data.BusinessAreaName == "" || params.data.BusinessAreaName == null ? { borderLeft: "5px solid #a94442" } : { borderLeft: "" }
      },
      {
        headerName: "Business Line",
        headerTooltip: "Business Line",
        field: "BusinessLineName",
        editable: false,
        tooltipField: "BusinessLine",
        cellStyle: (params) => params.data.BusinessLineName == "" || params.data.BusinessLineName == null ? { borderLeft: "5px solid #a94442" } : { borderLeft: "" }
      },
      {
        headerName: "Role",
        field: "RoleTitle",
        headerTooltip: "Role",
        tooltipField: "Role",
       // cellEditor: 'agRichSelectCellEditor',
        filter: false,
        hide: true,
        cellStyle: (params) => params.data.RoleTitle == "" ? { borderLeft: "5px solid #a94442" } : { borderLeft: "" }
        },
      {
        headerName: "Role",
        field: "Role",
        headerTooltip: "Role",
        tooltipField: "Role.name",
        cellEditor: 'agRichSelectCellEditor',
        filter: false,
        cellRenderer: roleName,
        keyCreator: function (Role) {
          return Role.name;
        },
        cellEditorParams: {
          cellRenderer: roleName,
          values: this.roleDropDownData
        },
        sortable: true,
        comparator: (valueA, valueB) => {
          const a = valueA.name;
          const b = valueB.name;
          if (a === b) return 0;
          else if (a > b) return 1;
          else return -1
        }

      },
      {
        editable: false,
        filter: false,
        headerTooltip: "Legal Hold",
        headerName: "Legal Hold",
        field: "OnLegalHold",
        tooltipField: "OnLegalHold",
        width: 75,
        cellStyle: { textAlign: "left" },
        cellRendererFramework: CheckboxComponent,
      },
      {
        editable: false,
        filter: false,
        headerTooltip: "Action",
        headerName: "Action",
        width: 75,
        cellRendererFramework: DeleteiconComponent,
      }
    ];

    this.defaultColDef = {
      editable: true,
      filter: true,
      sortable: true,
      suppressMovable: true,
      singleClickEdit: true
    }

    this.domLayout = 'autoHeight';
    this.showGrid = true;
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit()
    this.sharedService.userMangementObj.subscribe(api => {
      this.gridApi = api
    })
  }

  // This is get called whenevr the size of the grid changed;
  onGridSizeChanged(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  AddNewUser() {
    this.validateRow();
  }

  // Validate the grid data before adding new row and before saving data
  validateRow() {
    this.alertErrorBox = [];
    let errorMessage = messages.cellEmptyErrorMesssage;
    //To reset the filter and sorting while adding a new user row
    this.gridColumnApi.resetColumnState();
    this.gridApi.setFilterModel(null);
    let error = []
    let firstRow = this.gridApi.getDisplayedRowAtIndex(0);
    if (firstRow == undefined) {

      if (this.selectBusinessArea == "" || this.selectBusinessArea == null) {
        error.push({ "Business Area": errorMessage });
      }

      if (this.selectBusinessLine == "" || this.selectBusinessLine == null) {
        error.push({ "Business Line": errorMessage });
      }
      if (error.length == 0) {
        this.gridApi.applyTransaction({
          add: [{
            UserId: 0,
            FirstName: null,
            LastName: null,
            FullName: null,
            Email: null,
            GID: null,
            OrgCode: null,
            BusinessAreaName: this.roleBusinessArea.find(ba => ba.BusinessAreaId == Number(this.selectBusinessArea)).BusinessAreaName,
            BusinessLineName: this.roleBusinessLine.find(bl => bl.BusinessLineId == Number(this.selectBusinessLine)).BusinessLineName,
            Role: this.defaultRole,
            IsActive: true,
            CreatedBy: this.loggedInUser.UserId,
            CreatedOn: new Date(),
            ModifiedBy: null,
            ModifiedOn: null,
            OnLegalHold: false
          }],
          addIndex: 0 // To add the new row at top of the grid
        })
      }
      else if (error.length != 0) {
        this.openAlertDialog(error)
      }
    }
    else {
      let regex = new RegExp(/\^s*$/);

      if (firstRow.data.GID == "" || firstRow.data.GID == null || regex.test(firstRow.data.GID)) {
        error.push({ "GID": errorMessage })
      }
      if (firstRow.data.Email == "" || firstRow.data.Email == null || regex.test(firstRow.data.Email)) {
        error.push({ "Email": errorMessage })
      }
      if (firstRow.data.FirstName == "" || firstRow.data.FirstName == null || regex.test(firstRow.data.FirstName)) {
        error.push({ "First Name": errorMessage })
      }
      if (firstRow.data.LastName == "" || firstRow.data.LastName == null || regex.test(firstRow.data.LastName)) {
        error.push({ "Last Name": errorMessage })
      }
      if (firstRow.data.FullName == "" || firstRow.data.FullName == null || regex.test(firstRow.data.FullName)) {
        error.push({ "Full Name": errorMessage })
      }
      if (firstRow.data.OrgCode == "" || firstRow.data.OrgCode == null || regex.test(firstRow.data.OrgCode)) {
        error.push({ "Org Code": errorMessage })
      }

      if (error.length == 0) {
        this.gridApi.applyTransaction({
          add: [{
            UserId: 0,
            FirstName: null,
            LastName: null,
            FullName: null,
            Email: null,
            GID: null,
            OrgCode: null,
            BusinessAreaName: this.roleBusinessArea.find(ba => ba.BusinessAreaId == Number(this.selectBusinessArea)).BusinessAreaName,
            BusinessLineName: this.roleBusinessLine.find(bl => bl.BusinessLineId == Number(this.selectBusinessLine)).BusinessLineName,
            Role: this.defaultRole,
            IsActive: true,
            CreatedBy: this.loggedInUser.UserId,
            CreatedOn: new Date(),
            ModifiedBy: null,
            ModifiedOn: null,
            OnLegalHold: false
          }],
          addIndex: 0 // To add the new row at top of the grid
        })
      }
      else if (error.length != 0) {
        this.openAlertDialog(error)
      }
    }
  }

  // To show popup message when there is any error
  openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }

  onCellValueChanged(params) {
    // activate user;
    if (params.column.colDef.field == "Email") {
      this.allInActiveUser.forEach((row, index) => {
        if (row.Email == params.data.Email) {
          this.activateUserMessage = "User already exists in database with the following access permissions\n BusinessArea: "+ row.BusinessAreaName+
                                            "\nBusinessLine: "+ row.BusinessLineName +
                                            "\nRole: "+ row.RoleTitle +
                                            "\nState: InActive \n Do You want to enhance the current Role?" ;
          const dialogRef = this.dialog.open(DialogComponent, { data: { message: this.activateUserMessage , okBtn: "Yes", cancelBtn: "No" } })
          dialogRef.afterClosed().subscribe(result => {

            if (result) {
              row.IsActive = true;
              this.gridApi.applyTransaction({ remove: [params.data] });
              this.gridApi.applyTransaction({
                add: [row],
                addIndex: 0 // To add the new row at top of the grid
              })
              this.modifiedUserRows.push(row);

            } else {
              this.gridApi.applyTransaction({ remove: [params.data] });
            }
          })
        }
      }
      )

    }

    let valToEmit = {
      unsavedChanges: true,
      saveButtonClick: false
    }
    this.unSavedChanges.emit(valToEmit);

    if (params.data.UserId != 0) {
      let isPushed = false;
      if (this.modifiedUserRows.length == 0) {
        this.modifiedUserRows.push(params.data)
        isPushed = true
      }
      else {
        this.modifiedUserRows.forEach(row => {
          if (row.UserId == params.data.UserId) {
            isPushed = true
          }
        });
      }
      if (!isPushed) {
        this.modifiedUserRows.push(params.data)
      }
    }
  }

  validateModifiedRowData(rowData) {
    this.finalError = [];
    let GIdErrCount = 0;
    let FirstNameErrCount = 0;
    let LastNameErrCount = 0;
    let FullNameErrCount = 0;
    let EmailErrCount = 0
    let OrgCodeErrCount = 0;
    let errorMessage = messages.cellEmptyErrorMesssage;
    for (let row of rowData) {
      let regex = new RegExp(/\^s*$/);
      if (row.GID == "" || row.GID == null || regex.test(row.GID)) {
        if (GIdErrCount == 0) {
          this.finalError.push({ "GID": errorMessage })
        }
        GIdErrCount += 1
      }
      if (row.Email == "" || row.Email == null || regex.test(row.Email)) {
        if (EmailErrCount == 0) {
          this.finalError.push({ "Email": errorMessage })
        }
        EmailErrCount += 1
      }
      if (row.FirstName == "" || row.FirstName == null || regex.test(row.FirstName)) {
        if (FirstNameErrCount == 0) {
          this.finalError.push({ "First Name": errorMessage })
        }
        FirstNameErrCount += 1
      }
      if (row.LastName == "" || row.LastName == null || regex.test(row.LastName)) {
        if (LastNameErrCount == 0) {
          this.finalError.push({ "Last Name": errorMessage })
        }
        LastNameErrCount += 1
      }
      if (row.FullName == "" || row.FullName == null || regex.test(row.FullName)) {
        if (FullNameErrCount == 0) {
          this.finalError.push({ "Full Name": errorMessage })
        }
        FullNameErrCount += 1
      }
      if (row.OrgCode == "" || row.OrgCode == null || regex.test(row.OrgCode)) {
        if (OrgCodeErrCount == 0) {
          this.finalError.push({ "Org Code": errorMessage })
        }
        OrgCodeErrCount += 1
      }
      if (row.GID) {
        if (row.GID.length > 10) {
          this.finalError.push({ "GID": messages.userManagement.InvalidGID })
        }
      }
    }
    this.alertErrorBox = this.finalError
  }

  /*check duplicate entries in email and gid fields and retuns only the modified user row
   if there is no duplicate entries*/
  getModifiedGridData() {
    let rowData = [];
    let uniqueGIDs = [];
    let uniqueEmailIds = [];
    this.gridApi.forEachNode(node => rowData.push(node.data));
    this.validateModifiedRowData(rowData)

    let regex = new RegExp(/\^s*$/);
    for (let i of rowData) {
      if (uniqueGIDs.includes(i.GID)) {
        let duplicate = { GID: messages.userManagement.duplicateField }
        this.alertErrorBox.push(duplicate);
      } else if (!regex.test(i.GID)) {
        uniqueGIDs.push(i.GID);
      }
      if (uniqueEmailIds.includes(i.Email)) {
        let duplicate = { Email: messages.userManagement.duplicateField }
        this.alertErrorBox.push(duplicate);
      } else if (!regex.test(i.Email)) {
        uniqueEmailIds.push(i.Email);
      }
    }
    return this.modifiedUserRows;
  }

  onCancel() {
    // changes;
    let valToEmit = {
      unsavedChanges: false,
      saveButtonClick: true
    }
    this.unsvdAdminChanges = false;
    this.unSavedChanges.emit(valToEmit);
    // end;
    this.modifiedUserRows = [];
    this.getAllActiveAndInActiveUserList();
    this.getUserList();
  }

  dataToSend(modifiedUserRows) {
    let postData = {};
    this.modifiedUserRows = this.modifiedUserRows.filter(user => user.UserId != 0)
    this.gridApi.forEachNode(node => {
      if (node.data.UserId == 0) {
        modifiedUserRows.push(node.data)
      }
    })
    modifiedUserRows = modifiedUserRows.map(user => {
      let modifierId = null
      if (user.UserId != 0) {
        modifierId = this.loggedInUser.UserId;
      }
      return {
        UserId: user.UserId,
        FirstName: user.FirstName,
        LastName: user.LastName,
        FullName: user.FullName,
        Email: user.Email,
        GID: user.GID.trim(),
        OrgCode: user.OrgCode,
        RoleId: user.Role.id,
        BusinessAreaId: Number(this.selectBusinessArea),
        BusinessLineId: Number(this.selectBusinessLine),
        IsActive: user.IsActive,
        CreatedBy: user.CreatedBy,
        CreatedOn: user.CreatedOn,
        ModifiedBy: modifierId,
        ModifiedOn: null,
        OnLegalHold: user.OnLegalHold
      }
    })
    postData = {
      GID: this.loggedInUser.GID,
      UserId: this.loggedInUser.UserId,
      UserDetails: modifiedUserRows
    }
    return postData;
  }


  // save the added and modified user row
  submit(event?: any, pageName?: string) {
    this.submitted = true;
    let finalGridData = this.getModifiedGridData();

    if (this.alertErrorBox.length == 0 && this.errorMessage.length == 0) {
      this.postData = this.dataToSend(finalGridData);
      if (this.postData.UserDetails.length != 0) {
        this.userSvc.insertUpdateUserInfo(this.postData).subscribe(
          (res: any) => {
            finalGridData = null;
            this.submitResponse = res;
            let successCode = this.submitResponse.StatusCode;
            let errorCode = this.submitResponse.Data.ErrorCode;
            let errorMsgeFromBack = this.submitResponse.Data.Message;
            if (successCode == 200) {
              if (errorCode == 0) {
                this.submitted = false;
                this.modifiedUserRows = [];

                // changes;
                let valToEmit = {
                  unsavedChanges: false,
                  saveButtonClick: event == undefined ? false : true
                }
                this.unsvdAdminChanges = false;
                this.unSavedChanges.emit(valToEmit);
                // end;

                let dataSaveMessage = serverMessage.dataSaveMessage;
                this.toast.notify(dataSaveMessage, "success");
                this.getAllActiveAndInActiveUserList();
                this.getUserList();
              }
              else {
                //Show errorCode and errorMessage in the UI
                let errorContainer = [
                  { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
                  { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
                ]
                this.openAlertDialog(errorContainer)
                this.submitted = false;
                return false;
              }
            }
            else {
              let errorContainer = [
                { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
                { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
              ]
              this.openAlertDialog(errorContainer)
              this.submitted = false;
              return false;
            }
          },
          (error) => {
            this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
            this.submitted = false;
            return false;
          })
      } else {
        this.submitted = false;
        this.modifiedUserRows = [];

        // changes;
        let valToEmit = {
          unsavedChanges: false,
          saveButtonClick: event == undefined ? false : true
        }
        this.unsvdAdminChanges = false;
        this.unSavedChanges.emit(valToEmit);
        // end;
      }
    }
    else if (this.errorMessage.length != 0) {
      this.submitted = false;
      this.openAlertDialog([{ [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader }]);
    } else if (this.alertErrorBox.length != 0) {
      let alertErrorBox = this.alertErrorBox.filter((row, index) => {
        let jsonRow = JSON.stringify(row);
        return index === this.alertErrorBox.findIndex(obj => {
          return JSON.stringify(obj) === jsonRow;
        })
      })
      this.submitted = false;
      this.openAlertDialog(alertErrorBox);
    }
  }

  ngOnDestroy() {
    if (this.adminSubscription) {
      this.adminSubscription.unsubscribe()
    }
  }
}

// returns the role dropdown name 
const roleName = (params) => params.value.name;
const businessAreaName = (params) => params.value.BusinessAreaName;
const businessLineName = (params) => params.value.BusinessLineName;

function convUpperCase(params) {
  params.data.GID = params.data.GID ? params.data.GID.trim() : params.data.GID;
  params.data.GID = params.data.GID ? params.data.GID.toUpperCase() : params.data.GId
  return params.data.GID
}