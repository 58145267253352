import { HttpClient } from '@angular/common/http';
import { ViewEncapsulation, Component, OnInit } from '@angular/core';
import { RowNode } from 'ag-grid-community';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { GridlinkComponent } from '../gridlink/gridlink.component';
import { ProcessCostService } from '../../../../services/process-cost.service'
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { MSolutionType } from 'src/app/enum/MSolutionType';
import { serverMessage } from 'src/app/popUpMessages/serverMessage';
import { AlertDialogComponent } from 'src/app/alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MenuService } from 'src/app/services/menu.service';
import { Router } from '@angular/router';
import { CategorizationService } from 'src/app/services/categorization.service';

let countryCode;
@Component({
  selector: 'app-processcost',
  templateUrl: './refscenario.component.html',
  styleUrls: ['./refscenario.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ProcessCostRefScenarioComponent implements OnInit {

  public refProcessCostRowData: any;
  public refProcessCostDefaultColDef: any;
  public refProcessCostColumnDefs: any;
  public refProcessCostGridApi: any;
  public refProcessCostgridColumnApi: any;
  public domLayout: any;
  public rowClassRules: any;
  public rowHeight: any;
  public headerHeight: any;
  public ideaId: any;
  public solutionId: any;
  public dataRec: any;
  public getResponse: any;
  public errorMessage = [];
  public showGrid = false;
  public hasUnsavedChanges = false;
  public modifiedGridRows = [];

  constructor(
    private http: HttpClient,
    private sharedService: SharedServiceService,
    private localStorageService: LocalStorageService,
    private processCostService: ProcessCostService,
    public dialog: MatDialog,
    private menuService:MenuService,
    private router:Router   ,
    private catService: CategorizationService
    ) {

    this.refProcessCostColumnDefs = [
      {
        headerName: "",
        headerClass: "processcost-class",
        children: [
          {
            headerName: "Process Costs",  headerTooltip: "Process Costs",
            children: [
              // { headerName:"ID",field: 'id',minWidth: 10 },
              {
                headerName: "Subprocess",  headerTooltip: "Subprocess",
                cellStyle: { 'text-align': 'left !important' },
                width: 120,
                suppressSizeToFit: true,
                field: 'SubProcessTitle',
                tooltipField: "SubProcessTitle",
                cellRendererFramework: GridlinkComponent
              }
            ]
          },
        ]
      },
      {
        headerName: "Output",  headerTooltip: "Output",
        children: [
          {
            headerName: "One Time",  headerTooltip: "One Time",
            headerClass: "border-left",
            children: [
              {
                headerName: "effort [abs]",  headerTooltip: "effort [abs]", field: 'OutputOTEffort',  tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer, cellClass: 'numCell-align border-left', headerClass: 'border-left',
                valueGetter: function (params) {
                  if (params.node.data.OutputOTEffort == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputOTEffort;
                  }
                }
              },
              {
                headerName: "cost [abs]",  headerTooltip: "cost [abs]", field: 'OutputOTCost', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer, cellClass: 'numCell-align',
                valueGetter: function (params) {
                  if (params.node.data.OutputOTCost == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputOTCost;
                  }
                }
              },
              {
                headerName: "Total",  headerTooltip: "Total", field: 'OutputOTTotal', tooltipValueGetter: CurrencyCellRenderer,cellRenderer: CurrencyCellRenderer, cellClass: 'colorTotal numCell-align',
                valueGetter: function (params) {
                  if (params.node.data.OutputOTTotal == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputOTTotal;
                  }
                }/*valueGetter:oneTimeTotal*/
              },
            ]
          },

          {
            headerName: "Recurring/year",  headerTooltip: "Recurring/year",
            headerClass: "ry-header-class border-left",
            children: [
              {
                headerName: "effort [abs]",  headerTooltip: "effort [abs]", field: 'OutputRYEffort', tooltipValueGetter: CurrencyCellRenderer,cellRenderer: CurrencyCellRenderer, cellClass: 'numCell-align border-left', headerClass: 'border-left',
                valueGetter: function (params) {
                  if (params.node.data.OutputRYEffort == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputRYEffort;
                  }
                }
              },
              {
                headerName: "cost [abs]",  headerTooltip: "cost [abs]", field: 'OutputRYCost',  tooltipValueGetter: CurrencyCellRenderer,cellRenderer: CurrencyCellRenderer, cellClass: 'numCell-align',
                valueGetter: function (params) {
                  if (params.node.data.OutputRYCost == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputRYCost;
                  }
                }
              },
              {
                headerName: "Total",  headerTooltip: "Total", field: 'OutputRYTotal', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer, cellClass: 'colorTotal numCell-align',
                valueGetter: function (params) {
                  if (params.node.data.OutputRYTotal == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputRYTotal;
                  }
                }/*valueGetter:reccuringPerYrTotal*/
              },
            ]
          },

          {
            headerName: "Recurring over lifetime",  headerTooltip: "Recurring over lifetime",
            headerClass: "rl-header-class border-left",
            children: [
              {
                headerName: "effort [abs]",  headerTooltip: "effort [abs]", field: 'OutputRLEffort', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer, cellClass: 'numCell-align border-left', headerClass: 'border-left',
                valueGetter: function (params) {
                  if (params.node.data.OutputRLEffort == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputRLEffort;
                  }
                }
              },
              {
                headerName: "cost [abs]",  headerTooltip: "cost [abs]", field: 'OutputRLCost', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer, cellClass: 'numCell-align',
                valueGetter: function (params) {
                  if (params.node.data.OutputRLCost == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputRLCost;
                  }
                }
              },
              { headerName: "Total",  headerTooltip: "Total", field: 'OutputRLTotal',  tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer, cellClass: 'colorTotal numCell-align',
              valueGetter: function (params) {
                if (params.node.data.OutputRLTotal == null) {
                  return 0;
                } else {
                  return params.node.data.OutputRLTotal;
                }
              } },
            ]
          },

          {
            headerName: "Total",  headerTooltip: "Total",
            headerClass: "border-left",
            children: [
              {
                headerName: "Over Lifetime",  headerTooltip: "Over Lifetime", field: 'OutputFinalTotal',  tooltipValueGetter: CurrencyCellRenderer,
                cellRenderer: CurrencyCellRenderer, cellClass: 'colorTotal numCell-align border-left', headerClass: 'border-left', valueGetter: overLifetimeTotal
              },
            ]
          },
        ]
      },

    ];

    this.domLayout = 'autoHeight';
    this.rowClassRules = {
      'rowpin-background': function (params: any) {
        return params.data.SubProcessTitle === "Total process cost"
      },

    };
    this.refProcessCostDefaultColDef={
      suppressMovable: true,
      sortable: false,
    }
    this.rowHeight = 25;
    this.headerHeight = 48;

  }

  ngOnInit(): void {

    this.sharedService.getIdeaIdAndtitle();

    this.ideaId = this.localStorageService.get('ideaId');

    countryCode = this.localStorageService.get('countryCode');

    this.loadRefProcessCost();
  }
  
  public loadRefProcessCost() {
    let ideaId = this.ideaId;
    this.errorMessage = [];
    let solutionId = MSolutionType.ReferenceScenario;

    this.processCostService.getProcessCostData(ideaId, solutionId).subscribe((data) => {
      this.getResponse = data;
   
     
      let successCode = this.getResponse.StatusCode;
      if (successCode != 200) {
        let errorMsgeFromBack = this.getResponse.Data.Message;
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer);        
        return false;
      }
      else if (successCode == 200) {       
        this.dataRec = this.getResponse.Data;
       
        this.refProcessCostRowData = this.dataRec;
        this.showGrid = true;
      }
    }, (error) => {
      this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }]);
      return false;
    })
  }

  onRefProcessCostGridReady(params: any) {
    this.refProcessCostGridApi = params.api;
    this.refProcessCostgridColumnApi = params.columnApi;

    this.refProcessCostGridApi.setGroupHeaderHeight(25);
    setTimeout(() => {
      let pinnedBottomData = this.generatePinnedBottomData();
      this.refProcessCostGridApi.setPinnedBottomRowData([pinnedBottomData]);
    }, 1000)
  }

  onRefProcessCostGridSizeChanged(params) {
    this.refProcessCostGridApi = params.api;
    this.refProcessCostGridApi.sizeColumnsToFit();
  }

  generatePinnedBottomData() {
    // generate a row-data with null values
    let result = { "OutputOTEffort": null, 'OutputOTCost': null, "OutputOTTotal": null, "OutputRYEffort": null, "OutputRYCost": null, "OutputRYTotal": null, "OutputRLEffort": null, "OutputRLCost": null, "OutputRLTotal": null, "OutputFinalTotal": null }

    return this.calculatePinnedBottomData(result);

  }
  calculatePinnedBottomData(target: any) {
    let columnsWithAggregation = ["OutputOTEffort", "OutputOTCost", "OutputOTTotal", "OutputRYEffort", "OutputRYCost", "OutputRYTotal", "OutputRLEffort", "OutputRLCost", "OutputRLTotal", "OutputFinalTotal"]
    columnsWithAggregation.forEach(element => {

      this.refProcessCostGridApi.forEachNodeAfterFilter((rowNode: RowNode) => {
        if (rowNode.data[element])
          target[element] += Number(rowNode.data[element]);
      });
    })
    target['SubProcessTitle'] = 'Total process cost';
    return target;
  }
  public openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }

 // get called when user click on cancel and exit;
 onClickCancel() {
  this.hasUnsavedChanges = false;
  this.router.navigate(['/manageIdea'])
}
// move user to back page;
onClickBack() {
  this.hasUnsavedChanges = false;
  this.menuService.onClickNextAndBack("back")
}
// moves user to the next page;
onClickNext() {
  this.hasUnsavedChanges = false;
  this.menuService.onClickNextAndBack("next")
}

}
function overLifetimeTotal(params) {
  let data = params.node.data;
  params.data.OutputFinalTotal = Number(data.OutputOTTotal) + Number(data.OutputRLTotal);
  if (params.data.OutputFinalTotal == null) {
    params.data.OutputFinalTotal = 0;
  }
  return params.data.OutputFinalTotal;
}

function CurrencyCellRenderer(params: any) {
  return params.value == undefined ? null : new Intl.NumberFormat(countryCode).format(Number(parseFloat(params.value).toFixed(2)));
};




